import {
  READ_ACTION,
  CONSULTANT_RESOURCE,
  LEAD_PN,
  TRANSFER_CONSULTANT,
  MANAGE_ACTION,
  FEEDZ,
  USEFUL_LINKS,
  USEFUL_LINKS_FRANCHISE,
  SOLIDES,
  PLANEJADORES_SELECTION_MENU,
  LEAD_PN_MENU,
  MY_TEAM_MENU,
  IMPORTER_CONSULTANT_MENU
} from "@/constants/resources";

var navMenu = [
  {
    title: "Gestão de PNs",
    icon: "none",
    children: [
      { 
        title: "Seleção de planejadores",
        route: "consultants-selection",
        icon: "UserPlusIcon",
        resource: PLANEJADORES_SELECTION_MENU,
        action: READ_ACTION,
      },
      {
        title: "Leads PNs",
        route: "potential-partners-list",
        icon: "UserCheckIcon",
        resource: LEAD_PN_MENU,
        action: READ_ACTION,
      },
      {
        title: "Minha equipe",
        route: "consultants-list",
        icon: "UsersIcon",
        resource: MY_TEAM_MENU,
        action: READ_ACTION,
      },
      {
        title: "Transferir PN",
        route: "consultant-transfer",
        icon: "RepeatIcon",
        resource: TRANSFER_CONSULTANT,
        action: MANAGE_ACTION,
      },
      {
        title: "Feedz",
        href: "https://app.feedz.com.br/inicio",
        icon: "UserCheckIcon",
        resource: FEEDZ,
        action: READ_ACTION,
      },
      {
        title: "Sólides",
        href: "https://solides.com.br/",
        icon: "SmileIcon",
        resource: SOLIDES,
        action: READ_ACTION,
      },
      {
        title: "Links úteis",
        href: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/documents/Links+u%CC%81teis+-+Planejador+Futuro.pdf",
        icon: "ListIcon",
        resource: USEFUL_LINKS,
        action: READ_ACTION,
      },
      {
        title: "Links úteis franqueados",
        href: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/documents/Links+u%CC%81teis+-+Franqueado+Futuro.pdf",
        icon: "BookIcon",
        resource: USEFUL_LINKS_FRANCHISE,
        action: READ_ACTION,
      },
      {
        title: "Importações",
        route: "imports",
        icon: "UploadIcon",
        resource: IMPORTER_CONSULTANT_MENU,
        action: READ_ACTION,
      },
    ].filter(Boolean),
  },
];

export default navMenu;
